<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card>

                    <v-form @submit.prevent="save" ref="userForm">
                        <v-card-title>
                            <span v-show="registerPage">{{ $t('register') }}</span>
                            <span v-show="myAccountPage">{{ $t('my_account') }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                            id="emailField"
                                            :label="$t('email')"
                                            type="email"
                                            v-model="userDetails.email"
                                            :rules="emailRules"
                                            required
                                    />

                                    <v-text-field
                                            id="nameField"
                                            :label="$t('name')"
                                            type="text"
                                            v-model="userDetails.name"
                                            :rules="nameRules"
                                            required
                                    />

                                    <v-autocomplete
                                            id="genderField"
                                            :label="$t('gender')"
                                            v-model="userDetails.gender"
                                            :items="getGender"
                                            required
                                    />

                                    <v-text-field v-if="registerPage"
                                                  id="passwordField"
                                                  :label="$t('password')"
                                                  type="password"
                                                  v-model="userDetails.password"
                                                  :rules="passwordRules"
                                                  required
                                    />

                                    <v-text-field
                                            id="companyField"
                                            :label="$t('company')"
                                            type="text"
                                            v-model="userDetails.company"
                                            :rules="companyRules"
                                            required
                                    />

                                    <v-autocomplete
                                            id="companyTypeField"
                                            :label="$t('company_type')"
                                            v-model="userDetails.company_type"
                                            :items="getCompanyTypes"
                                    />

                                    <v-text-field
                                            id="jobTitleField"
                                            :label="$t('job_title')"
                                            type="text"
                                            v-model="userDetails.job_title"
                                    />
                                </v-col>

                                <v-col cols="6">
                                    <v-text-field
                                            id="addressField"
                                            :label="$t('address')"
                                            type="text"
                                            v-model="userDetails.address"
                                            :rules="addressRules"
                                            required
                                    />

                                    <v-text-field
                                            id="postcodeField"
                                            :label="$t('postcode')"
                                            type="text"
                                            v-model="userDetails.postcode"
                                            :rules="postcodeRules"
                                            required
                                    />

                                    <v-text-field
                                            id="cityField"
                                            :label="$t('city')"
                                            type="text"
                                            v-model="userDetails.city"
                                            :rules="cityRules"
                                            required
                                    />

                                    <v-text-field
                                            id="countryField"
                                            :label="$t('country')"
                                            type="text"
                                            v-model="userDetails.country"
                                            :rules="countryRules"
                                            required
                                    />

                                    <v-autocomplete v-if="registerPage"
                                                    id="motivationField"
                                                    :label="$t('motivation')"
                                                    v-model="userDetails.motivation"
                                                    :items="getMotivation"
                                                    :rules="motivationRules"
                                                    required
                                    />

                                    <v-checkbox v-if="registerPage"
                                                id="agreeField"
                                                :label="$t('agree_terms')"
                                                v-model="agreeToTerms"
                                                :rules="agreeToTermsRules"
                                                required>
                                    </v-checkbox>

                                    <a v-show="registerPage" id="termsLink" class="termslink" @click="terms">
                                        {{ $t('terms') }}
                                    </a>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn v-show="registerPage" id="loginButton" to="/login" color="success">
                                <v-icon dark left>mdi-account</v-icon>
                                {{ $t('already_registered') }}
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn v-show="registerPage" id="registerButton" type="submit" color="info">
                                <v-icon dark left>mdi-account-plus</v-icon>
                                {{ $t('register') }}
                            </v-btn>

                            <v-btn v-show="myAccountPage" id="submitButton" type="submit" color="info">
                                <v-icon dark left>mdi-send</v-icon>
                                {{ $t('save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>

                </v-card>
            </v-col>
        </v-row>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import userService from '@/services/UserService.js'

    export default {
        name: "UserDetails",
        props: ['page'],

        computed: {
            ...mapState(['user', 'stmp']),
            ...mapGetters(['getGender', 'getMotivation', 'getCompanyTypes']),
        },

        mounted() {
            if (this.page == 'register') {
                this.registerPage = true
            }

            if (this.page == 'my_account') {
                this.myAccountPage = true
            }

            this.init()
        },

        data() {
            return {
                registerPage: false,
                myAccountPage: false,

                userDetails: {
                    name: '',
                    gender: '',
                    email: '',
                    password: '',
                    company: '',
                    company_type: '',
                    job_title: '',
                    address: '',
                    postcode: '',
                    city: '',
                    country: '',
                    motivation: '',
                    role: '',
                    status: '',
                    stmp: null,
                },
                agreeToTerms: false,

                nameRules: [
                    value => !!value || this.$t('required'),
                ],
                emailRules: [
                    value => !!value || this.$t('required'),
                    value => value.includes('@') || this.$t('email_invalid'),
                ],
                genderRules: [
                    value => !!value || this.$t('required'),
                ],
                companyRules: [
                    value => !!value || this.$t('required'),
                ],
                addressRules: [
                    value => !!value || this.$t('required'),
                ],
                postcodeRules: [
                    value => !!value || this.$t('required'),
                ],
                cityRules: [
                    value => !!value || this.$t('required'),
                ],
                countryRules: [
                    value => !!value || this.$t('required'),
                ],

                passwordRules: [],
                motivationRules: [],
                agreeToTermsRules: [],

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            init() {
                if (this.registerPage) {
                    this.userDetails.role = 'Visitor'
                    this.userDetails.status = 'Registered'

                    this.passwordRules = [value => !!value || this.$t('required')]
                    this.motivationRules = [value => !!value || this.$t('required')]
                    this.agreeToTermsRules = [value => !!value || this.$t('required')]
                } else {
                    // Fetch user record
                    userService.find(this.user)
                        .then(response => {
                            this.userDetails = response.data
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarColor = "error"
                            this.snackbarMessage = this.$t('invalid_data')
                        })
                }
            },

            terms() {
                // Redirect to terms page
                this.$router.push('/terms')
            },

            save() {
                if (this.$refs.userForm.validate()) {
                    // Register
                    if (this.registerPage) {
                        this.userDetails.stmp = this.stmp

                        userService.register(this.userDetails)
                            .then(() => {
                                // Redirect to thankyou page
                                this.$router.push('/thankyou')
                            })
                            .catch(() => {
                                this.snackbar = true
                                this.snackbarColor = "error"
                                this.snackbarMessage = this.$t('duplicate_account')
                            })
                    }

                    // My account
                    if (this.myAccountPage) {
                        userService.update(this.userDetails)
                            .then(() => {
                                this.snackbar = true
                                this.snackbarColor = "success"
                                this.snackbarMessage = this.$t('data_saved')
                            })
                            .catch(() => {
                                this.snackbar = true
                                this.snackbarColor = "error"
                                this.snackbarMessage = this.$t('invalid_data')
                            })
                    }

                }
            },
        }

    }
</script>

<style scoped>
    .termslink {
        text-decoration: none;
    }
</style>
